<template>
  <div class="t">{{ dataValue.modelRow['modelName'] }}</div>
  <div class="allAttribute">
    <div class="aS" v-for="attribute in dataValue.attributeList.output.list">
      <div class="l">{{ attribute['typeName'] }}：</div>
      <div class="r">
        <a-select :class="allEvent.attributeList.setColor(attribute['setType'])" v-model:value="attribute['setType']">
          <a-select-option value="" style="color: #bbbbbb;">不选择</a-select-option>
          <template v-for="val in attribute['typeValue']">
            <a-select-option :value="val">{{ val }}</a-select-option>
          </template>
        </a-select>
      </div>
    </div>
  </div>
  <div style="text-align: center;">
    <a-button type="primary" @click="allEvent.attributeSet.save">保存</a-button>
  </div>

</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "attribute_xx_set",
  props : ['model_row'],
  emits : ['goClose'],
  setup(props, { emit }) {

    let dataValue = reactive({
      modelRow : props.model_row,

      attributeList : {
        input : {
          "typeName" : "",
        },
        output : {
          "allCount" : 0,
          "list" : []
        }
      }
    });

    let allEvent = {

      attributeList : {

        setColor : ( value )=>{
          return value === "" ? "aSelectNone" : "aSelect";
        },

        getDefaultType : (typeName)=>{
          let setTypeValue = "";
          dataValue.modelRow['attributes'].forEach((v)=>{
            if( typeName === v['key'] ) setTypeValue = v['value'];
          });
          return setTypeValue;
        },

        http : ()=>{

          proxy.post('/admin/data.center/attribute.list', dataValue.attributeList.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.attributeList.output.allCount = res.data["allCount"];
              dataValue.attributeList.output.list = res.data["list"];
              dataValue.attributeList.output.list.forEach((v) => {
                v['setType'] = allEvent.attributeList.getDefaultType(v['typeName']);
              })
            }
          })

        }

      },

      attributeSet : {

        allCount : 0,

        setRow : ( key, value)=>{

          let allAttr = dataValue.modelRow['attributes'];

          for( let i=0; i<allAttr.length; i++ )
          {
            if( key === allAttr[i]['key'] ){
              allAttr[i]['value'] = value;
              return;
            }
          }

          allAttr.push({ "key": key, "value": value } );

        },

        save : ()=>{

          allEvent.attributeSet.allCount = dataValue.attributeList.output.list.length;

          dataValue.attributeList.output.list.forEach((v) => {

            let input = {
              "modelName" : dataValue.modelRow['modelName'],
              "attributeTypeName" : v['typeName'],
              "attributeTypeValue" : v['setType'],
              "delHistory" : 1
            }


            proxy.post('/admin/data.center/models.attribute.add', input).then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {

                allEvent.attributeSet.setRow( v['typeName'], v['setType'] );

                allEvent.attributeSet.allCount --;
                if( allEvent.attributeSet.allCount === 0 ) emit('goClose');
              }
            })

          });
        }

      },


      init :()=>{
        allEvent.attributeList.http();
      }
    }

    allEvent.init();

    return{
      dataValue, allEvent
    }
  }
}
</script>

<style scoped>
.t{ float: left; margin-bottom: 20px; border-bottom: 1px solid #dddddd; padding-bottom: 8px; font-weight: bold;
  text-align: center; width: 100%; }
.aSelect{ margin: 0 0 10px 0; width: 150px; text-align: left; }
.aSelectNone{ margin: 0 0 10px 0; width: 150px; text-align: left; color: #bbbbbb; }
.allAttribute{ text-align: center; }
.aS{ float: left; width: 100%; clear: both; border-bottom: 1px solid #eeeeee; margin-bottom: 10px; }
.aS .l{ float: left; line-height: 36px; }
.aS .r{ float: right; }
</style>