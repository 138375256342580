<template>

  <div>

    <div class="divSearch">

      <div class="dCount">
        <div>检索结果:</div>
        <div>{{ dataValue.allModels.output.allCount}}条</div>
      </div>

      <div class="dTitle">基本条件</div>

      <a-select class="company" v-model:value="dataValue.allModels.input.brandName"
                @change="allEvent.allModels.toPage(1)">
        <a-select-option value="">全部品牌</a-select-option>
        <template v-for="brand in dataValue.allBrand.output.list">
          <a-select-option :value="brand['brand']">{{ brand['brand'] }}</a-select-option>
        </template>
      </a-select>



      <a-input class="model" placeholder="包括型号" @pressEnter="allEvent.allModels.toPage(1)" v-model:value="dataValue.allModels.input.modelKey"/>
      <a-button class="buttSearch" @click="allEvent.allModels.toPage(1)"><search-outlined /></a-button>

      <div class="dTitle">
        <label class="l">自定义标签</label>
        <a-tooltip placement="top">
          <template #title>
            <span>重置自定义标签筛选条件</span>
          </template>
          <redo-outlined class="r" @click="allEvent.allModels.xxReset" />
        </a-tooltip>
      </div>

      <template v-for="attribute in dataValue.attributeList.output.list">

        <a-select class="company" v-model:value="attribute['setType']" @change="allEvent.allModels.toPage(1)"
                  style="margin-right: 20px;width: 100%;">
          <a-select-option value="">[{{ attribute['typeName'] }}]</a-select-option>
          <template v-for="val in attribute['typeValue']">
            <a-select-option :value="val">{{ val }}</a-select-option>
          </template>
        </a-select>

      </template>


    </div>
    <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
    <div v-if="dataValue.loading === false" class="divTab">
      <table class="ahuiTable">
        <tr>
          <th>编号</th>
          <th class="model2">型号</th>
          <th>品牌</th>
          <th class="min" v-for="xxAttribute in dataValue.allModels.output.attributeXXNames">{{ xxAttribute }}</th>
          <th v-for="attribute in dataValue.allModels.output.attributeNames">{{ attribute['keyName'] }}</th>
          <th colspan="2">&nbsp;</th>
        </tr>
        <tr v-for="aRow in dataValue.allModels.output.list">
          <td class="min">{{ aRow['indexId'] }}</td>
          <td>{{ aRow['modelName'] }}</td>
          <td class="min">{{ aRow['brand'] }}</td>

          <td class="min" v-for="xxAttribute in dataValue.allModels.output.attributeXXNames">
            {{ allEvent.allModels.attrGet(aRow, xxAttribute) }}
          </td>
          <td class="center" v-for="attribute in dataValue.allModels.output.attributeNames">
            {{ allEvent.allModels.attrGet(aRow, attribute['keyName']) }}
          </td>
          <td class="min"><a class="aBQSet" @click="allEvent.attributeSet.open(aRow)">标签</a></td>
          <td class="min"><a class="aBQSet" @click="allEvent.bi.open(aRow)">分析</a></td>
        </tr>
        <tr>
          <td colspan="100" class="pages">
            <APages :number_of_page="dataValue.allModels.output.numberOfPage"
                    :this_page="dataValue.allModels.output.page" :all_page="dataValue.allModels.output.allPage"
                    @toPage="allEvent.allModels.toPage" :all_count="dataValue.allModels.output.allCount"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>


  <a-drawer
      :title="dataValue.bi.modelName +'的分析信息'"
      width="1150"
      :visible="dataValue.bi.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.bi.close"
  >
    <biModel :model_name="dataValue.bi.modelName"  />
  </a-drawer>

  <a-drawer
      title="修改设置型号的标签"
      width="330"
      :visible="dataValue.attributeSet.show"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.attributeSet.close"
  >

    <attrSet @goClose="allEvent.attributeSet.close" :model_row="dataValue.attributeSet.modelRow"   />
  </a-drawer>


</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import attrSet from "@/view/data_center/attribute_xx_set"
import biModel from "@/view/bi/bi_model"


export default {
  name : "all_models",
  emits : [ 'setMenuKey' ],
  components : { APages, attrSet, biModel },
  setup(props, { emit }) {
    emit('setMenuKey', 'datacenter.xhbjck');

    let dataValue = reactive({
      loading : false,

      bi:{
        show: false,
        modelName : ""
      },

      attributeSet : {
        show : false,
        modelRow : null
      },

      attributeList : {
        input : {
          "typeName" : "",
        },
        output : {
          "allCount" : 0,
          "list" : []
        }
      },

      allBrand : {
        input : {
          "brandKey" : ""
        },
        output : {
          "allCount" : 0,
          "list" : []
        }
      },

      allModels : {
        input : {
          "brandName" : "",
          "modelKey" : "",
          "page" : 1,
          "numberOfPage" : 100,
          "attributeKeys" : []
        },
        output : {
          "allCount" : 0,
          "allPage" : 0,
          "page" : 0,
          "numberOfPage" : 0,
          "attributeNames" : [],
          "attributeXXNames" : [],
          "list" : []
        }
      }
    })

    let allEvent = {

      bi : {

        open : (row)=>{
          // console.log(row['modelName'])
          dataValue.bi.modelName = row['modelName'];
          dataValue.bi.show = true;
        },

        close: ()=>{
          dataValue.bi.show = false;
        }

      },

      attributeSet : {

        open :( row )=>{
          dataValue.attributeSet.modelRow = row;
          dataValue.attributeSet.show = true;
        },

        close : ()=>{
          dataValue.attributeSet.show = false;
        }

      },

      attributeList : {

        http : () => {

          proxy.post('/admin/data.center/attribute.list', dataValue.attributeList.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.attributeList.output.allCount = res.data["allCount"];
              dataValue.attributeList.output.list = res.data["list"];

              dataValue.attributeList.output.list.forEach((v) => {
                v['setType'] = "";
              })
            }
          })
        }

      },

      allBrand : {

        http : () => {

          proxy.post('/admin/data.center/all.brand', dataValue.allBrand.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.allBrand.output.allCount = res.data["allCount"];
              dataValue.allBrand.output.list = res.data["list"];
            }
          })

        }

      },

      allModels : {

        attrGet : (row, noteName) => {

          let returnKey = "";
          row['attributes'].forEach((v) => {
            if (v['key'] === noteName) returnKey += v['value'] + ", "
          })
          return returnKey.substring(0, returnKey.length - 2);
        },

        toPage : (pageId) => {
          dataValue.allModels.input.page = pageId;
          allEvent.allModels.http();
        },

        xxReset : ()=>{
          dataValue.attributeList.output.list.forEach((v)=>{
            v['setType'] = "";
          });
          allEvent.allModels.http();
        },

        http : () => {

          // 判断设置所有的自定义条件
          dataValue.allModels.input.attributeKeys = [];
          dataValue.attributeList.output.list.forEach((v)=>{
            dataValue.allModels.input.attributeKeys.push( { "key":v['typeName'], "value":v['setType'] } );
          });



          dataValue.loading = true;

          proxy.post('/admin/data.center/all.models', dataValue.allModels.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {

              dataValue.allModels.output.allCount = res.data["allCount"];
              dataValue.allModels.output.allPage = res.data["allPage"];
              dataValue.allModels.output.page = res.data["page"];
              dataValue.allModels.output.numberOfPage = res.data["numberOfPage"];
              dataValue.allModels.output.attributeNames = res.data["attributeNames"];
              dataValue.allModels.output.attributeXXNames = res.data["attributeXXNames"];
              dataValue.allModels.output.list = res.data["list"];

              dataValue.loading = false;

            }
          })
        }

      },

      init : () => {
        allEvent.allModels.http();
        allEvent.allBrand.http();
        allEvent.attributeList.http();
      }
    }

    allEvent.init();

    return {
      dataValue,
      allEvent
    }

  }
}
</script>

<style scoped>
.dCount{ margin-top: 10px; margin-bottom: 15px; float: left; width: 100%; border-bottom: 1px solid #dddddd; padding-bottom: 5px; }
.dCount div:nth-child(1){ float: left; margin-left: 5px; font-weight:bold; }
.dCount div:nth-child(2){ float: right; color: saddlebrown; }
.dTitle{ float: left; width: 100%; clear: both; border-bottom: 1px solid #eeeeee; margin-bottom: 8px; margin-left: 5px;
  margin-top: 10px; padding-bottom: 3px; }
.dTitle .l{ float: left; font-weight:bold;}
.dTitle .r{ float: right;margin: 0 10px 0 0; position: relative; top:2px; cursor: pointer; transition: all 1s; }
.dTitle .r:hover{ color: #225DDC; transform: rotateZ(360deg); transition: all 0.4s; }
.divSearch { position: fixed; top: 60px; left: 0; width: 200px; padding: 10px ; }
.divSearch .company { clear: both; width: 100%; margin-bottom: 15px; }
.divSearch .model { clear: both; width: 145px; margin-bottom: 15px; text-transform:uppercase; }
.divSearch .buttSearch{ float: right; width: 30px; padding: 0 0 0 2px; }
.divTab{ position: absolute; top: 0; left: 200px; width: calc( 100% - 200px ); padding: 10px 10px 10px 0; }

.loadingClass { width: 100%; text-align: center; padding-top: 100px; }

.model2 { white-space: nowrap; }
.center { text-align: center; }
.aBQSet{ margin: 0 3px 0 3px; white-space: nowrap; }

</style>